import React from "react";
import ComponentTab from "../../components/tabs/ComponentTab";

const HomeDark = () => {
  return (
    <div className="yellow">
      <div className="demo-sticker">
        <img src="img/logo_white.png" />
      </div> 
      <ComponentTab />
    </div>
  );
};

export default HomeDark;

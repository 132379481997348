import React from "react";
import Portfolio from "../portfolio/Portfolio";

export default function Gallery() {
  return (
    <>
      <div
        className="title-section text-left text-sm-center"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <h1>
          my <span>portfolio</span>
        </h1>
        <span className="title-bg">works</span>
      </div>
      <div
        className="container grid-gallery main-content"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <Portfolio />
      </div>
    </>
  );
}

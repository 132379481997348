import React, { useState } from "react";
import ModalContent from "./modal/ModalContent";
import ModalVideo from 'react-modal-video';

import masterData from "../../MasterData.json";

const Portfolio = (props) => {
  const [portfolioContent, setPortfolioContent] = useState(
    masterData.contents.portfolioContent
  );
  //const [showModal, setShowModal] = useState(false);
  const [modalSelect, setModalSelect] = useState("");
  const [isOpenMovieModal, setIsOpenMovieModal] = useState(false);
  const [videoId, setVideoId] = useState("");

  function toggleShowModal(title) {
   // setShowModal(!showModal);
    setModalSelect(title);
  }

  function playVideo(sequence){
    portfolioContent.map((item, i) =>{
      if(item.isMovie){
        setVideoId(item.imageContents[sequence].videoId);
      }
    });
    setIsOpenMovieModal(true);
  }


  return (
    <>
    <ul className="row grid justify-content-center">
      {portfolioContent.map((item, i) => (
        <li data-aos="fade-right" data-aos-duration="1200" data-aos-delay="0">
         
            <figure onClick={()=>toggleShowModal(item.title)}>
              <img src={item.img} alt="Portolio" />
              <div className=" hover-content-wrapper">
                <span className="content-title">{item.title}</span>
              </div>
            </figure>
          { modalSelect==item.title && <ModalContent imageContents={item.imageContents}  isOpen = {true} onClose={toggleShowModal} isMovie={item.isMovie} onVideoPlay={(sequence)=>playVideo(sequence)} />}
        </li>
        
      ))}
    </ul>
    <ModalVideo channel='youtube' autoplay isOpen={isOpenMovieModal} videoId={videoId} onClose={() => setIsOpenMovieModal(false)} />
    </>
  );
};

export default Portfolio;

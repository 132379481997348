import React from "react";
import { TabList, Tab } from "react-tabs";
import masterData from "../MasterData.json";

export default function AppMenu(props) {
  return (
    <div className="header">
      <TabList className="icon-menu  revealator-slideup revealator-once revealator-delay1">
        {masterData.contents.menuItem.map((item, i) => (
          <Tab className="icon-box" key={i} onClick={() => props.onSelect(i)}>
            <i className={`fa ${item.icon}`}></i>
            <h2>{item.menuName}</h2>
          </Tab>
        ))}
      </TabList>
    </div>
  );
}

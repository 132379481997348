import React, { useState } from "react";
import Modal from "react-modal";
import Index from "../../components/about/index";
import masterData from "../../MasterData.json";



const Hero = (props) => {
  const [roles, setRoles] = useState(
    masterData.contents.heroContent.heroDesignation.split(",")
  );

  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + masterData.contents.heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={`img/hero/${masterData.contents.heroContent.heroMobileImage}.jpg`}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              I'm {masterData.contents.heroContent.heroTitleName}.
              {roles.map((item, i) => (
                <span>{item}</span>
              ))}
            </h1>
            <p>{masterData.contents.heroContent.heroDescriptionsp1}</p>
            <p>{masterData.contents.heroContent.heroDescriptionsp2}</p>
            <p>{masterData.contents.heroContent.heroDescriptionsp3}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;

import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";

const ModalContent = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
 
  const [sequence, setSequence] = useState(0);

  function toggleModal() {
    setIsOpen(!isOpen);
    props.onClose("");
  }

  function _customAction(index){
    setSequence(index);
  }

  function openVideo(){
    setIsOpen(!isOpen);
    props.onVideoPlay(sequence);
    
  }
  

  return (
    console.log("image contnets"),
    console.log(props.imageContents),
    (
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="custom-modal dark"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModal}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner portfolio">
            <div className="slideshow">
              <figure>
              {!props.isMovie && <ImageGallery
                  items={props.imageContents}
                  showThumbnails={false}
                  autoPlay={true}
                />}
               { props.isMovie && <ImageGallery
                  items={props.imageContents}
                  showThumbnails={false}
                  autoPlay={false} onSlide = {(index)=>_customAction(index)} onClick={()=>openVideo()}
                />}
              </figure>
            </div>
          </div>
        </div>
      </Modal>
      
    )
  );
};
export default ModalContent;

import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import axios from "axios";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });

  const onSubmit = (data, e) => {
    
  
  axios.post('https://www.nils-photography.com/service/sendQuery.php', JSON.stringify(data))
  .then(response => {
    var res = JSON.stringify(response);
    if(res.includes("SMTP Error")){
      alert("Something wrong! Please try again");
    }else{
      alert("We have received your message");
      e.target.reset();
    }
      
  });


  };

  return (
    <>
      <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("name", {
                  required: "This input is required.",
                  maxLength: {
                    value: 100,
                    message: "This input must not exceed 100 characters",
                  },
                })}
                type="text"
                name="name"
                placeholder="YOUR NAME"
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p style={{ color: "#bf1650" }} key={type}>
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("email", {
                  required: "This input is required.",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
                type="email"
                name="email"
                placeholder="YOUR EMAIL"
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p style={{ color: "#bf1650" }} key={type}>
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("phone", {
                  required: "Phone Number is Required",
                  pattern: {
                    value:
                      /^\(?([0-9]{0,3})\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,4})$/,
                    message: "Phone number format is not correct",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter 10 digit phone number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter 10 digit phone number",
                  },
                })}
                type="text"
                name="phone"
                placeholder="YOUR CONTACT NUMBER"
              />
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p style={{ color: "#bf1650" }} key={type}>
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: "Subject is Required" })}
                type="text"
                name="subject"
                placeholder="YOUR SUBJECT"
              />
              <ErrorMessage
                errors={errors}
                name="subject"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p style={{ color: "#bf1650" }} key={type}>
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: "Message is Required" })}
                name="message"
                placeholder="YOUR MESSAGE"
              ></textarea>
              <ErrorMessage
                errors={errors}
                name="message"
                render={({ messages }) => {
                  console.log("messages", messages);
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p style={{ color: "#bf1650" }} key={type}>
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Send Message</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;

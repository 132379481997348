import React, { useState } from "react";
import { Tabs, TabPanel } from "react-tabs";
import HomeTab from "./HomeTab";
import AppMenu from "../AppMenu";
import Contact from "../Contact";
import Gallery from "./Gallery";
import Address from "../Address";
import Social from "../Social";

export default function ComponentTab() {
  const [tabIndex, setTabIndex] = useState(0);

  const displayTab = (index) => {
    setTabIndex(index);
    console.log("Tab select --" + index);
  };

  return (
    <Tabs>
      <AppMenu theme={"dark"} onSelect={displayTab} />
      <div className="tab-panel_list">
        {tabIndex === 0 && (
          <TabPanel className="home">
            <HomeTab onMore={displayTab} />
          </TabPanel>
        )}
        {tabIndex === 1 && (
          <TabPanel className="portfolio professional">
            <Gallery />
          </TabPanel>
        )}
        {tabIndex === 2 && (
          <TabPanel className="contact">
            <div
              className="title-section text-left text-sm-center"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h1>
                get in <span>touch</span>
              </h1>
              <span className="title-bg">contact</span>
            </div>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                    Don't be shy !
                  </h3>
                  <p className="open-sans-font mb-4">
                    Feel free to get in touch with me. I am always open to
                    discussing new projects, creative ideas or opportunities to
                    be part of your visions.
                  </p>
                  <Address />
                  {/* End Address */}

                  <Social />
                  {/* End Social */}
                </div>
                {/* Left Side Ends */}

                {/*  Contact Form Starts  */}
                <div className="col-12 col-lg-8">
                  <Contact />
                </div>
                {/*  Contact Form Ends */}
              </div>
            </div>
          </TabPanel>
        )}
      </div>
    </Tabs>
  );
}

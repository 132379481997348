import React from "react";
import Hero from "../hero/Hero";

export default function HomeTab(props) {
  return (
    <div
      className="container-fluid main-container container-home p-0 "
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="color-block d-none d-lg-block"></div>
      <Hero {...props} />
    </div>
  );
}
